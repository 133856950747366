import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "contexts/AuthContext";
import { fetchDirectly } from "../../common/apiUtils";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import RHDynamicLoadingMask from "../../components/common/RHDynamicLoadingMask";

const MAX_RETRIES = 3;

// Custom MultiSelect component
const MultiSelect = ({ options, selected, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md  shadow-sm px-4 py-2 bg-zinc-700 text-sm font-medium text-gray-200 hover:bg-zinc-600 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          Toggle Columns
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-zinc-900 ring-1 ring-black ring-opacity-5 z-50">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map((option) => (
              <label
                key={option.value}
                className="flex items-center px-4 py-2 text-sm text-gray-200 hover:bg-zinc-700 cursor-pointer"
              >
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-indigo-600 mr-2"
                  checked={!selected.includes(option.value)}
                  onChange={() => onChange(option.value)}
                />
                {option.label}
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const CopyableCell = ({ value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setCopied(true);
      setTimeout(() => setCopied(false), 500); // Reset after 1 second
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div
      onClick={handleCopy}
      className="cursor-pointer hover:bg-zinc-700 transition-colors duration-200 relative"
      title="Click to copy"
    >
      {value}
      {copied && (
        <span className="absolute top-0 right-0 bg-green-500 text-white text-xs px-1 py-0.5 rounded">
          Copied!
        </span>
      )}
    </div>
  );
};

const AdminDashboard = ({ onNotification }) => {
  const { getAccessTokenSilently } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [hiddenColumns, setHiddenColumns] = useState(["row_id"]);

  const fetchWithRetry = useCallback(
    async (endpoint, params = null) => {
      let attempts = 0;
      let lastError;

      while (attempts < MAX_RETRIES) {
        try {
          const url = params ? `${endpoint}?${params.toString()}` : endpoint;
          const response = await fetchDirectly({
            fullUrl: url,
            method: "GET",
            token: await getAccessTokenSilently(),
          });
          return response;
        } catch (error) {
          lastError = error;
          attempts++;

          console.error(`Attempt ${attempts} failed:`, error);

          if (attempts < MAX_RETRIES) {
            const retryDelay = 1000 * Math.pow(2, attempts); // Exponential backoff
            console.warn(`Retrying in ${retryDelay / 1000} seconds...`);
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
          }
        }
      }

      throw lastError;
    },
    [getAccessTokenSilently]
  );

  const fetchDashboardData = useCallback(
    async ({ page = 1 }) => {
      const params = new URLSearchParams({
        cursor: (page - 1).toString(),
        page_size: pageSize.toString(),
      });

      if (selectedOrgId) {
        params.append("org_ids", selectedOrgId);
      }

      console.log("Fetching dashboard data with params:", params.toString());
      return fetchWithRetry(`v1/admin/dashboard`, params);
    },
    [fetchWithRetry, pageSize, selectedOrgId]
  );

  const fetchUniqueOrganizations = useCallback(() => {
    return fetchWithRetry(`v1/org`);
  }, [fetchWithRetry]);

  const {
    data: dashboardData,
    isLoading: isLoadingDashboard,
    isError: isErrorDashboard,
    error: errorDashboard,
    refetch: refetchDashboard,
  } = useQuery({
    queryKey: ["adminDashboard", currentPage, pageSize, selectedOrgId],
    queryFn: () => fetchDashboardData({ page: currentPage }),
    keepPreviousData: true,
    retry: false, // We're handling retries manually
    onError: (error) => {
      console.error("Error fetching dashboard data:", error);
      onNotification(
        `Failed to fetch dashboard data. Error: ${
          error.message || "Unknown error"
        }`,
        "error"
      );
    },
  });

  const {
    data: uniqueOrgsResponse,
    isLoading: isLoadingOrgs,
    isError: isErrorOrgs,
    error: errorOrgs,
    refetch: refetchOrgs,
  } = useQuery({
    queryKey: ["uniqueOrganizations"],
    queryFn: fetchUniqueOrganizations,
    staleTime: 5 * 60 * 1000,
    retry: false, // We're handling retries manually
    onError: (error) => {
      console.error("Error fetching organizations:", error);
      onNotification(
        `Failed to fetch organizations. Please try again later.`,
        "error"
      );
    },
  });

  const uniqueOrgs = useMemo(() => {
    let orgs = [];
    if (uniqueOrgsResponse && Array.isArray(uniqueOrgsResponse)) {
      orgs = uniqueOrgsResponse.filter((org) => org.archive !== true);
    } else if (
      uniqueOrgsResponse?.data &&
      Array.isArray(uniqueOrgsResponse.data)
    ) {
      orgs = uniqueOrgsResponse.data.filter((org) => org.archive !== true);
    }

    // Sort the organizations alphabetically by name
    return orgs.sort((a, b) => a.name.localeCompare(b.name));
  }, [uniqueOrgsResponse]);

  const allColumns = useMemo(
    () => [
      { header: "Row ID", accessorKey: "row_id" },
      { header: "Customer Org", accessorKey: "customer_org" },
      { header: "Cust Org ID", accessorKey: "cust_org_id" },
      { header: "Customer Product", accessorKey: "customer_product" },
      { header: "Cust Prod ID", accessorKey: "cust_prod_id" },
      { header: "Prod Unique Name", accessorKey: "prod_unique_name" },
      { header: "Customer Environment", accessorKey: "customer_environment" },
      { header: "Cust Env ID", accessorKey: "cust_env_id" },
      { header: "Env Unique Name", accessorKey: "env_unique_name" },
      { header: "Customer Sandbox", accessorKey: "customer_sandbox" },
      { header: "Cust Sand ID", accessorKey: "cust_sand_id" },
      { header: "Base URL", accessorKey: "base_url" },
      { header: "DB Name", accessorKey: "db_name" },
    ],
    []
  );

  const columns = useMemo(() => {
    return allColumns
      .filter((column) => !hiddenColumns.includes(column.accessorKey))
      .map((column) => ({
        ...column,
        cell: ({ getValue }) => <CopyableCell value={getValue()} />,
      }));
  }, [allColumns, hiddenColumns]);

  const tableData = useMemo(() => dashboardData?.data || [], [dashboardData]);

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const toggleColumnVisibility = (accessorKey) => {
    setHiddenColumns((prev) =>
      prev.includes(accessorKey)
        ? prev.filter((key) => key !== accessorKey)
        : [...prev, accessorKey]
    );
  };

  const totalPages = Math.ceil(
    (dashboardData?.page_meta?.total || 0) / pageSize
  );

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const handleOrgChange = (event) => {
    setSelectedOrgId(event.target.value);
    setCurrentPage(1);
    refetchDashboard(); // Refetch dashboard data when org changes
  };

  const handleRetry = () => {
    refetchDashboard();
    refetchOrgs();
  };

  return (
    <div className="w-full bg-zinc-800 text-gray-200 p-6">
      <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>

      {/* Filters row */}
      <div className="flex justify-between items-center mb-4">
        {/* Org Filter Dropdown */}
        <div className="flex items-center">
          <label htmlFor="org-filter" className="mr-2">
            Filter by Organization:
          </label>
          {isLoadingOrgs ? (
            <span>Loading organizations...</span>
          ) : isErrorOrgs ? (
            <span className="text-red-500">
              Error loading organizations. Please try refreshing the page.
            </span>
          ) : (
            <select
              id="org-filter"
              value={selectedOrgId}
              onChange={handleOrgChange}
              className="bg-zinc-700 text-gray-200 rounded px-2 py-1 focus:outline-none"
            >
              <option value="">All Organizations</option>
              {uniqueOrgs.map((org) => (
                <option key={org.org_id} value={org.org_id}>
                  {org.name}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Column visibility toggle */}
        <MultiSelect
          options={allColumns.map((col) => ({
            label: col.header,
            value: col.accessorKey,
          }))}
          selected={hiddenColumns}
          onChange={toggleColumnVisibility}
        />
      </div>

      {isLoadingDashboard || isLoadingOrgs ? (
        <RHDynamicLoadingMask />
      ) : isErrorDashboard || isErrorOrgs ? (
        <div className="text-red-500">
          Error fetching data. Please try again.
          <button
            onClick={handleRetry}
            className="ml-2 px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
          >
            Retry
          </button>
        </div>
      ) : (
        <>
          {/* Table content */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-zinc-700">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="px-4 py-2 text-left text-xs font-medium text-gray-300 uppercase tracking-wider whitespace-nowrap overflow-hidden text-ellipsis"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="bg-zinc-800 divide-y divide-gray-700">
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="px-3 py-1 whitespace-nowrap text-xs text-gray-300"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Footer with pagination controls */}
          <div className="mt-4 flex justify-end items-center text-sm">
            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <span className="mr-2">Rows per page</span>
                <select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  className="bg-zinc-700 text-gray-200 rounded px-2 py-1 focus:outline-none"
                >
                  {[10, 20, 50, 100].map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
              <div>{`${tableData.length} of ${
                dashboardData?.page_meta?.total || 0
              } rows`}</div>
              <div>{`Page ${currentPage} of ${totalPages}`}</div>
              <div className="flex space-x-1">
                <button
                  onClick={() => setCurrentPage(1)}
                  disabled={currentPage === 1}
                  className="px-2 py-1 bg-zinc-700 text-gray-200 rounded disabled:opacity-50 focus:outline-none"
                >
                  &lt;&lt;
                </button>
                <button
                  onClick={() => setCurrentPage((old) => Math.max(old - 1, 1))}
                  disabled={currentPage === 1}
                  className="px-2 py-1 bg-zinc-700 text-gray-200 rounded disabled:opacity-50 focus:outline-none"
                >
                  &lt;
                </button>
                <button
                  onClick={() =>
                    setCurrentPage((old) => Math.min(old + 1, totalPages))
                  }
                  disabled={currentPage === totalPages}
                  className="px-2 py-1 bg-zinc-700 text-gray-200 rounded disabled:opacity-50 focus:outline-none"
                >
                  &gt;
                </button>
                <button
                  onClick={() => setCurrentPage(totalPages)}
                  disabled={currentPage === totalPages}
                  className="px-2 py-1 bg-zinc-700 text-gray-200 rounded disabled:opacity-50 focus:outline-none"
                >
                  &gt;&gt;
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminDashboard;
