import React, { useContext, useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import AccountContext from "contexts/AccountContext";
import { useAuth } from "contexts/AuthContext";
import RHDynamicLoadingMask from "../../../components/common/RHDynamicLoadingMask";

const CopyableField = ({ label, value }) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        console.log("Copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <div className="text-sm mb-2">
      <p className="font-semibold mb-1">{label}:</p>
      <p
        className="text-zinc-400 truncate cursor-pointer hover:text-zinc-300"
        onClick={handleCopy}
        title={`${value}\nClick to copy`}
      >
        {value}
      </p>
    </div>
  );
};

const EnvironmentSidebar = ({
  environment,
  onSpinActionConfirmation,
  onArchiveConfirmation,
  onNotification,
  onRefresh,
}) => {
  const { currentStateData } = useContext(AccountContext);
  const { getAccessTokenSilently } = useAuth();
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const pollIntervalRef = useRef(null);

  const startPolling = () => {
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
    }
    pollIntervalRef.current = setInterval(onRefresh, 15000); // 15 seconds
  };

  const stopPolling = () => {
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
      pollIntervalRef.current = null;
    }
  };

  useEffect(() => {
    return () => stopPolling(); // Cleanup on unmount
  }, []);

  useEffect(() => {
    if (!environment) return;

    const stableStates = ["on", "off", "created", "destroyed"];
    const transitionalStates = ["creating", "initializing", "terminating", "destroying"];

    if (stableStates.includes(environment.state)) {
      stopPolling();
    } else if (transitionalStates.includes(environment.state)) {
      startPolling();
    }
  }, [environment]);

  const getButtonStyle = (state) => {
    if (!isActionable(state)) {
      return "bg-gray-500 hover:bg-gray-600 cursor-not-allowed";
    }
    switch (state) {
      case "on":
        return "bg-red-500 hover:bg-red-600";
      case "off":
      case "created":
        return "bg-green-500 hover:bg-green-600";
      default:
        return "bg-gray-500 hover:bg-gray-600";
    }
  };

  const getStatusColor = (state) => {
    switch (state) {
      case "on":
        return "bg-green-500";
      case "off":
      case "destroyed":
        return "bg-red-500";
      case "creating":
        return "bg-yellow-500";
      case "initializing":
        return "bg-yellow-500";
      case "terminating":
      case "destroying":
        return "bg-yellow-500";
      case "created":
        return "bg-green-500";
      default:
        return "bg-gray-500";
    }
  };

  const getStatusText = (state) => {
    switch (state) {
      case "on":
        return "ONLINE";
      case "off":
        return "OFFLINE";
      case "creating":
        return "CREATING";
      case "created":
        return "CREATED";
      case "initializing":
        return "INITIALIZING";
      case "terminating":
        return "TERMINATING";
      case "destroying":
        return "DESTROYING";
      case "destroyed":
        return "DESTROYED";
      default:
        return "UNKNOWN";
    }
  };

  const isActionable = (state) => {
    return ["on", "off", "created"].includes(state);
  };

  const getActionButtonText = (state) => {
    if (environment.archive && state === "off") return "Unarchive to Spin Up";
    switch (state) {
      case "on":
        return "Spin Down";
      case "off":
        return "Spin Up";
      default:
        return "Processing...";
    }
  };

  const handleSpinAction = () => {
    if (isSpinning || !isActionable(environment?.state)) {
      return;
    }

    if (environment.archive && environment.state === "off") {
      onNotification("Cannot spin up an archived environment. Please unarchive it first.", "warning");
      return;
    }

    const action = environment.state === "on" ? "down" : "up";

    setIsSpinning(true);
    onSpinActionConfirmation(action, environment.name, environment.environment_id);
    setIsSpinning(false);
    startPolling(); // Start polling after initiating a spin action
  };

  const handleSandboxClick = () => {
    setErrorMsg("");
    const connectedSandbox = currentStateData?.product?.sandboxes.find(
      (sandbox) => sandbox.sandboxId === environment.sandbox_id
    );
    if (!connectedSandbox) {
      setErrorMsg("Sandbox not found");
      return;
    }
    history.push(
      `/org/${currentStateData.org.shortName}/product/${currentStateData.product.shortName}/sandbox/${connectedSandbox.shortName}`
    );
  };

  const handleRefresh = useCallback(() => {
    if (typeof onRefresh === "function") {
      onRefresh();
    }
  }, [onRefresh]);

  const isEnvironmentOffline = environment?.state === "off" || environment?.state === "destroyed";

  const handleArchiveToggle = () => {
    if (!isEnvironmentOffline) {
      onNotification("You must spin down the environment before archiving.", "warning");
      return;
    }

    onArchiveConfirmation(environment.name, environment.environment_id, !environment.archive);
  };

  if (!environment) {
    return (
      <div className="p-4 h-full flex items-start">
        <div className="bg-zinc-800 w-[250px] flex flex-col rounded-lg shadow-lg overflow-hidden">
          <RHDynamicLoadingMask loadMessage="" />
        </div>
      </div>
    );
  }
  return (
    <div className="p-4 h-full flex items-start justify-center">
      <div className="bg-zinc-800 w-[250px] flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="p-3 border-b border-zinc-700">
          <div className="flex items-center justify-between gap-2 mb-4">
            <div className="flex items-center gap-2">
              <span className={`inline-block w-4 h-4 rounded-full ${getStatusColor(environment.state)}`}></span>
              <span className="font-medium text-sm">
                Status: <span className="font-bold">{getStatusText(environment.state)}</span>
              </span>
            </div>
            <div className="flex gap-2">
              <button onClick={handleRefresh} className="text-zinc-400 hover:text-zinc-200" title="Refresh status">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          {isActionable(environment.state) && (
            <button
              onClick={handleSpinAction}
              disabled={isSpinning || (environment.archive && environment.state === "off")}
              className={`text-white px-4 py-2 rounded-md w-full mb-2 ${getButtonStyle(environment.state)} ${
                isSpinning || (environment.archive && environment.state === "off")
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              {isSpinning ? "Processing..." : getActionButtonText(environment.state)}
            </button>
          )}
          {environment.archive && environment.state === "off" && (
            <p className="text-xs text-zinc-400 mt-2 text-center">Unarchive the environment to spin it up</p>
          )}
        </div>
        <div className="p-4 text-sm flex-grow overflow-y-auto" style={{ maxHeight: "calc(100vh - 13rem)" }}>
          <p className="mb-2">
            <strong>Connected Sandbox:</strong>
          </p>
          <div className="bg-zinc-700 p-3 rounded-md mb-4 hover:bg-zinc-600 transition-colors duration-300">
            <div
              onClick={handleSandboxClick}
              className="text-violet-400 hover:text-violet-300 underline cursor-pointer flex items-center justify-between"
            >
              <span className="truncate mr-2">
                {currentStateData?.product?.sandboxes.find((sandbox) => sandbox.sandboxId === environment.sandbox_id)
                  ?.sandboxName || "Unknown Sandbox"}
              </span>
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                />
              </svg>
            </div>
          </div>
          {errorMsg && (
            <div className="p-4">
              <RHDynamicLoadingMask loadMessage={errorMsg} showError={true} />
            </div>
          )}
          <CopyableField label="Base URL" value={environment.base_url} />
          <CopyableField label="Unique Name" value={environment.unique_name} />
          <CopyableField label="Environment ID" value={environment.environment_id} />
          <div className="text-sm">
            <p className="font-semibold mb-1">Last Modified:</p>
            <p className="text-zinc-400">{dayjs(environment.last_modified).format("MMMM DD YYYY HH:mm:ss")}</p>
          </div>
        </div>
        <div className="p-4 border-t border-zinc-700">
          <button
            onClick={handleArchiveToggle}
            disabled={!isEnvironmentOffline}
            className={`px-4 py-2 rounded-md w-full border transition-colors duration-300 ${
              !isEnvironmentOffline
                ? "bg-zinc-600 text-zinc-400 cursor-not-allowed"
                : environment.archive
                ? "bg-green-700 text-green-100 border-green-500 hover:bg-green-600"
                : "bg-zinc-700 text-red-500 border-red-500 hover:bg-zinc-600 hover:text-red-400"
            }`}
          >
            {environment.archive ? "Unarchive" : "Archive"}
          </button>
          {!isEnvironmentOffline && (
            <p className="text-xs text-zinc-400 mt-2 text-center">
              Environment must be offline to {environment.archive ? "unarchive" : "archive"}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(EnvironmentSidebar);
