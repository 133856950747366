import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CopyIcon } from "../../assets/svgs";

const BreadcrumbInfo = ({ info }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [copiedKey, setCopiedKey] = useState(null);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!info) return null;

  const truncate = (str, length) =>
    str.length > length ? str.substring(0, length) + "..." : str;

  const formatValue = (key, value) => {
    if (value === undefined || value === null) return "N/A";
    if (typeof value === "boolean") return value.toString();
    if (typeof value === "object") return JSON.stringify(value);
    return value.toString();
  };

  const handleCopy = (key, value) => {
    navigator.clipboard.writeText(value).then(() => {
      setIsOpen(false); // Close the dropdown immediately
      setCopiedKey(key);
      setTimeout(() => {
        setCopiedKey(null);
      }, 1500);
    });
  };

  const mapKeyToLabel = (type, key) => {
    const mappings = {
      product: {
        id: "Product ID:",
        name: "Product Name:",
        shortName: "Product Short Name:",
        uniqueName: "Product Unique Name:",
      },
      sandbox: {
        id: "Sandbox ID:",
        name: "Sandbox Name:",
        shortName: "Sandbox Short Name:",
      },
      organization: {
        id: "Org ID:",
        name: "Org Name:",
        shortName: "Org Short Name:",
      },
      environment: {
        id: "Environment ID:",
        name: "Environment Name:",
        shortName: "Environment Short Name:",
        uniqueName: "Environment Unique Name:",
        url: "Environment Base URL:",
      },
    };

    return (
      mappings[type.toLowerCase()]?.[key] ||
      `${key.charAt(0).toUpperCase() + key.slice(1)}:`
    );
  };

  const mainInfo = `${info.type} Info`;
  const detailsArray = Object.entries(info)
    .filter(([key]) => key !== "type")
    .map(([key, value]) => ({
      key,
      label: mapKeyToLabel(info.type, key),
      value: formatValue(key, value),
    }));

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className="relative inline-block">
      <button
        ref={buttonRef}
        onClick={toggleDropdown}
        className="flex items-center space-x-2 bg-zinc-800 hover:bg-zinc-700 text-zinc-300 hover:text-white transition-colors rounded-md px-3 py-1 focus:outline-none h-8"
      >
        <span className="text-sm font-medium">{mainInfo}</span>
        <motion.svg
          animate={{ rotate: isOpen ? 180 : 0 }}
          className="w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </motion.svg>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={dropdownRef}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-1 bg-zinc-800 rounded-lg shadow-xl py-1 z-50 border border-zinc-700 overflow-hidden whitespace-nowrap"
          >
            {detailsArray.map(({ key, label, value }) => (
              <button
                key={key}
                onClick={() => handleCopy(key, value)}
                className="w-full flex items-center px-3 py-1 text-xs text-zinc-300 hover:bg-zinc-700 transition-colors"
              >
                <span className="font-medium mr-2 flex-shrink-0 w-48 truncate text-left">
                  {label}
                </span>
                <span className="text-right mr-2 flex-shrink truncate max-w-[250px]">
                  {value}
                </span>
                <CopyIcon className="w-3 h-3 text-violet-400 flex-shrink-0" />
              </button>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {copiedKey && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute right-0 mt-2 bg-green-500 text-white px-4 py-2 rounded shadow-lg z-50 whitespace-nowrap"
          >
            <p className="text-xs font-medium">Copied to clipboard</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BreadcrumbInfo;
