import { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  MenuItem,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";

import FieldWrapper from "../../../components/common/FieldWrapper";
import RHConfirmDialog from "../../../components/common/RHConfirmDialog";
import FormField from "./FormField";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";

import ToolBar from "./ToolBar";
import { useTableStyles } from "./styles";

import {
  capitalizeFirstLetter,
  getErrorMessage,
  getGeneralFormStylesSx,
  scrollToBottom,
} from "./tableHelpers";

export default function ArmadaTable(props) {
  const {
    title,
    rowKey,
    parentRowKey,
    parentFriendlyNameKey,
    columns,
    tableData,
    initialState,
    nestedDataToUpdate,
    textFieldFilter,
    setSnackBar,
    setSnackBarState,
    friendlyName,
    friendlyNameKey,
    newModel,
    newModelArgs,
    retrieveSelectedRows,
    retrieveNewItemAsSelectedRow,
    onSelectedRowsChanged,
    selectionModel,
    createHandler,
    updateHandler,
    deleteHandler,
    filterMode,
    onFilterChanged,
    rowCount,
    paginationMode,
    onPaginationChanged,
    sortingMode,
    onSortModelChanged,
    disableEditMode,
    detailTitleOverride,
    searchOrCreateText,
    hideSearchBoxTitle,
    isRowEditable,
    disableRowClick,
    onCancel,
    onEdit,
    additionalEditButtons,
    onImport,
    onExport,
  } = props;

  const [selectedItem, setSelectedItem] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [newItemCreated, setNewItemCreated] = useState(false);
  const [editDetail, setEditDetail] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  const [errorMsg, setErrorMsg] = useState();

  const [selectedInlineRows, setSelectedInlineRows] = useState();
  const [editableRows, setEditableRows] = useState();
  const [localData, setLocalData] = useState();

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [forceRemountKey, setForceRemountKey] = useState(0);

  const isEditModeDisabled =
    disableEditMode === undefined ? false : disableEditMode;

  const classes = useTableStyles();

  let defaultInitialState;

  if (!initialState) {
    defaultInitialState = {
      pagination: {
        paginationModel: {
          pageSize: 50,
        },
      },
      columns: {
        columnVisibilityModel: {
          last_modified_timestamp: false,
        },
      },
      sorting: {
        sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
      },
    };
  }

  const [dialogProps, setDialogProps] = useState({
    open: false,
    title: "",
    content: "",
    onConfirm: null,
  });

  useEffect(() => {
    if (nestedDataToUpdate && tableData) {
      setLocalData(tableData[nestedDataToUpdate]);
    } else if (tableData) {
      setLocalData(tableData);
      selectionModel && setRowSelectionModel(selectionModel);
    }
  }, [tableData]);

  const handleDialogConfirmDelete = async () => {
    try {
      setDialogProps((prev) => ({ ...prev, open: false }));

      if (nestedDataToUpdate) {
        const prevIndex = localData.findIndex(
          (row) => row?.[rowKey] === selectedItem[rowKey]
        );
        if (prevIndex > -1) {
          let newRows = Object.assign([], localData);
          newRows.splice(prevIndex, 1);
          actuallySave(newRows);
        }
      } else {
        await deleteHandler(selectedItem);

        setEditedDetails();
        toggleShowDetail(false);
        setEditDetail(false);
        setNewItemCreated(false);
        setSnackBarState(true);
        setSnackBar({
          severity: "success",
          message: `Deleted "${selectedItem?.[friendlyNameKey ?? rowKey]}"`,
        });
      }
    } catch (error) {
      setErrorMsg(getErrorMessage(error) || "An unknown error occurred");
      setSnackBarState(true);
      setSnackBar({
        severity: "error",
        message: `Failed to delete "${
          selectedItem?.[friendlyNameKey ?? rowKey]
        }", ${error?.response?.data?.detail || "An error occurred"}`,
      });
    }
  };

  const handleDialogCancel = () => {
    setDialogProps((prev) => ({ ...prev, open: false }));
  };

  const handleRowClick = (params) => {
    const item = params.row;
    retrieveSelectedRows && retrieveSelectedRows(item);
    setNewItemCreated(false);
    setSelectedItem(item);
    toggleShowDetail(true);
    setEditDetail(false);
    setEditedDetails(item);
    scrollToBottom();
  };

  const handleDelete = async () => {
    const snackDisplayId = selectedItem[friendlyNameKey]
      ? `\n\n${selectedItem[friendlyNameKey]}\n${selectedItem[rowKey]}\n\n`
      : detailTitleOverride
      ? detailTitleOverride(selectedItem)
      : `\n\n${selectedItem[rowKey]}\n\n`;

    if (!dialogProps.open) {
      setDialogProps({
        open: true,
        title: "Confirm Deletion",
        content: `Are you sure you want to delete? ${snackDisplayId} This action cannot be undone.`,
        onConfirm: () => handleDialogConfirmDelete(),
        onCancel: () => handleDialogCancel(),
      });
    }
  };

  function handleEdit() {
    setEditDetail(true);
    setNewItemCreated(false);
    setEditedDetails(selectedItem);
    onEdit && onEdit();
  }

  const setPersistentErrorMsg = (msg) => {
    setErrorMsg(msg);
  };

  const toggleShowDetail = (state) => {
    setShowDetail(state);
    setPersistentErrorMsg();
  };

  const handleCancel = () => {
    setForceRemountKey((forceRemountKey) => forceRemountKey + 1);
    setPersistentErrorMsg();
    if (newItemCreated) {
      toggleShowDetail(false);
    }
    setEditableRows();
    setEditDetail(false);

    setEditedDetails(selectedItem);
    if (nestedDataToUpdate) {
      setLocalData(tableData[nestedDataToUpdate]);
    } else setLocalData(tableData);
    onCancel && onCancel(newItemCreated);
  };

  const handleAddNew = () => {
    setRowSelectionModel([]);
    let newItem = new newModel(...(newModelArgs ?? []));
    setNewItemCreated(true);
    setSelectedItem(newItem);
    setEditedDetails(newItem);
    setEditDetail(true);

    retrieveSelectedRows &&
      retrieveNewItemAsSelectedRow &&
      retrieveSelectedRows(null, /*isEdit*/ true);

    if (!nestedDataToUpdate || nestedDataToUpdate) {
      toggleShowDetail(true);
    }
  };

  function prepareJsonForTransport(editedItem) {
    const jsonCols = columns.filter((x) => x.type == "json");
    jsonCols.forEach((x) => {
      if (editedItem[x.field] && typeof editedItem[x.field] !== "object") {
        let isValid = false;
        try {
          const editedItemCopy = { ...editedItem };
          editedItemCopy[x.field] = JSON.parse(editedItem[x.field]);
          isValid = true;
        } catch (error) {
          console.error(error);
        }
        if (isValid) {
          editedItem[x.field] = JSON.parse(editedItem[x.field]);
        } else return;
      }
    });
  }

  function validateData() {
    const errors = columns
      .filter((x) => x.required && x.field !== rowKey)
      .reduce((acc, field) => {
        if (!editedDetails[field.field] || editedDetails[field.field] === "") {
          acc.push(field.headerName);
        }
        if (field.type === "json") {
          try {
            JSON.stringify(editedDetails[field.field]);
          } catch (error) {
            acc.push(field.headerName);
          }
        }
        return acc;
      }, []);
    if (errors.length > 0) {
      let newError = new Error("Missing Required Data");
      newError.response = {
        data: {
          desc: `${errors.join(", ")} ${
            errors.length > 1 ? "are" : "is"
          } required.`,
        },
      };
      throw newError;
    }
  }

  const actuallySave = async (newRows) => {
    setPersistentErrorMsg();
    let item;

    // Iterate through the editedDetails object and remove any null, undefined, or empty string values
    let editedItem = Object.entries({
      ...selectedItem,
      ...editedDetails,
    }).reduce((acc, [key, value]) => {
      if (
        value === null ||
        value === undefined ||
        value === "" ||
        columns.find((column) => column.field === key && column.nullify)
      ) {
        return acc;
      } else {
        acc[key] = value;
        return acc;
      }
    }, {});

    if (nestedDataToUpdate) {
      rowKeyValue = tableData[parentRowKey];
      editedItem = updateNestedNonInlineData(editedItem, newRows);
    }

    prepareJsonForTransport(editedItem);

    try {
      validateData();
      if (newItemCreated && !nestedDataToUpdate) {
        item = await createHandler(editedItem);
      } else {
        item = await updateHandler(editedItem);
      }

      setSnackBarState(true);
      const snack = editedDetails?.[friendlyNameKey ?? rowKey] ?? "item.";
      setSnackBar({
        severity: "success",
        message: `Changes saved to ${
          detailTitleOverride ? detailTitleOverride(snack) : snack
        }`,
      });

      setNewItemCreated(false);

      const savedItem = {
        ...editedItem,
        ...(Array.isArray(item.data) ? item.data[0] : item.data),
      };

      setRowSelectionModel(savedItem[rowKey]);
      setSelectedItem(savedItem);
      setEditedDetails(savedItem);
    } catch (error) {
      setSnackBarState(true);
      setSnackBar({ severity: "error", message: "Failed to save changes" });

      const serverMsg = getErrorMessage(error);
      if (serverMsg) {
        console.log(serverMsg);
        setPersistentErrorMsg(serverMsg);
      }
      console.error(error);
      return;
    }

    //There is no new item if there is a nested addition, it is an update to a previous db entry. This also assumes that the parentRowKey is going to be the same for all nested items. If it isn't nested, we'll have to alias a different api name in the sandbox context provider which will iterate through each updated row in localData and do a create or update for each one.
    if (nestedDataToUpdate) {
      try {
        if (localData.length > 0) {
          let editedItem = Object.assign(
            { ...tableData },
            { [nestedDataToUpdate]: newRows }
          );
          item = await updateHandler(editedItem);

          setSnackBarState(true);
          setSnackBar({
            severity: "success",
            message: `Changes saved to ${
              parentFriendlyNameKey
                ? tableData[parentFriendlyNameKey ?? friendlyNameKey ?? rowKey]
                : item?.data[friendlyNameKey ?? rowKey]
            }`,
          });
        }
        setSelectedItem(item?.data);
      } catch (error) {
        setNewItemCreated(false);
        setSnackBarState(true);
        setSnackBar({ severity: "error", message: "Failed to save changes" });
        console.error(error);
      }
    }

    setEditDetail(false);
  };

  const updateNestedNonInlineData = (editedItem, newRows) => {
    editedItem = { ...editedItem };
    if (newRows) {
      editedItem[nestedDataToUpdate] = newRows;
    }
    let nestedData = [...tableData[nestedDataToUpdate]];
    let editedChildIdx = nestedData.findIndex(
      (x) => x[rowKey] == editedItem[rowKey]
    );
    if (editedChildIdx >= 0) {
      nestedData[editedChildIdx] = editedItem;
    } else {
      nestedData = [...nestedData, editedItem];
    }
    tableData[nestedDataToUpdate] = newRows ?? nestedData;
    editedItem = tableData;
    return editedItem;
  };

  const visibleColumns = columns.filter((x) => !x.alwaysHidden);

  const onFilterChangeHandler = async (filterModel) => {
    if (filterMode == "server") {
      onFilterChanged(filterModel);
    }
  };

  const onPaginationChangeHandler = async (options) => {
    if (paginationMode == "server") {
      if (onPaginationChanged) {
        onPaginationChanged(options);
      }
    }
  };

  const onSortModelChangeHandler = async (sortModel) => {
    if (sortingMode == "server") {
      if (onSortModelChanged) {
        onSortModelChanged([...sortModel]);
      }
    }
  };

  const showDetailForm = () => {
    const rowIsSelectedOrIsNewItem =
      newItemCreated || rowSelectionModel?.length > 0;
    return showDetail && rowIsSelectedOrIsNewItem;
  };

  return (
    <>
      <Box className={classes.rootConfigPage}>
        {localData && (
          <DataGrid
            {...props}
            className={classes.table}
            initialState={initialState ?? defaultInitialState}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            getRowId={(row) => row[rowKey]}
            onRowClick={disableRowClick ? () => {} : handleRowClick}
            rows={localData}
            columns={visibleColumns.filter((col) => col.type !== "parent")}
            filterMode={filterMode ?? "client"}
            onFilterModelChange={onFilterChangeHandler}
            pagination
            paginationMode={paginationMode ?? "client"}
            onPaginationModelChange={onPaginationChangeHandler}
            sortingMode={sortingMode ?? "client"}
            onSortModelChange={onSortModelChangeHandler}
            rowCount={rowCount}
            autoHeight
            editMode="row"
            onRowSelectionModelChange={(items) => {
              setSelectedInlineRows(items);
              setRowSelectionModel(items);
              onSelectedRowsChanged && onSelectedRowsChanged(items);
            }}
            rowSelectionModel={rowSelectionModel}
            slots={{
              toolbar: () =>
                ToolBar({
                  handleAddNew,
                  handleCancel,
                  handleDelete,
                  handleEdit,
                  handleSave: actuallySave,
                  selectedItem,
                  classes,
                  hideHeader: true,
                  selectedInlineRows,
                  activelyEditing: editableRows ? true : false,
                  onImport,
                  onExport,
                  //friendlyName: !friendlyNameKey ? columns[0].headerName : undefined,
                  friendlyName: !friendlyName
                    ? !friendlyNameKey
                      ? columns[0].headerName
                      : undefined
                    : friendlyName,
                  title: title,
                  hideSearchBoxTitle: hideSearchBoxTitle,
                  searchOrCreateText: searchOrCreateText,
                }),
            }}
            sx={{
              bgcolor: "background.paper",
              border: "none",
              minWidth: 0,
              p: 1,
            }}
          />
        )}
        {showDetailForm() && (
          //renderTableDetailView isn't defined as a JSX component because it forces re-renders for each keydown, thus losing focus on the text field.

          <Box className={classes.tablesDetailContainer}>
            <Box className={classes.tableDetailView}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    {detailTitleOverride
                      ? detailTitleOverride(selectedItem[rowKey])
                      : selectedItem[rowKey]
                      ? `${title ?? capitalizeFirstLetter(title)} Details - ${
                          selectedItem[friendlyNameKey || rowKey]
                        }`
                      : `New ${title ?? capitalizeFirstLetter(title)}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: "background.paper" }}>
                  <Box className={classes.tableDetailViewContents}>
                    <Box className={classes.tableDetailViewContentsButtonBar}>
                      {errorMsg ? (
                        <Alert severity="error">{errorMsg}</Alert>
                      ) : (
                        <></>
                      )}

                      {editDetail ? (
                        <>
                          <Tooltip title="Exit edit mode">
                            <Button
                              onClick={() => {
                                handleCancel();
                              }}
                              variant="outlined"
                            >
                              Cancel
                            </Button>
                          </Tooltip>
                          <Tooltip title={`Save ${title.toUpperCase()} Table`}>
                            <Button onClick={actuallySave} variant="contained">
                              Save
                            </Button>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          {!isEditModeDisabled ? (
                            <>
                              <Tooltip title="Enter edit mode">
                                <Button
                                  onClick={handleEdit}
                                  variant="outlined"
                                  disabled={
                                    isRowEditable &&
                                    !isRowEditable(editedDetails)
                                  }
                                >
                                  Edit
                                </Button>
                              </Tooltip>
                              {additionalEditButtons &&
                                additionalEditButtons.map((btn) => btn())}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Box>

                    <Box
                      className={classes.tableDetailForm}
                      style={{ marginBottom: "25px" }}
                    >
                      {columns
                        .filter(
                          (field) =>
                            !newItemCreated && field.type === "metadata"
                        )
                        .map((field, i) => (
                          <div
                            key={field.field + title + i + field.type}
                            style={{
                              ...getGeneralFormStylesSx(field),
                              display: "inline-block",
                            }}
                          >
                            <FormField
                              selectedItem={selectedItem}
                              editedDetails={editedDetails}
                              setEditedDetails={setEditedDetails}
                              editDetail={editDetail}
                              field={field}
                              rowKey={rowKey}
                              newItemCreated={newItemCreated}
                            />
                          </div>
                        ))}
                    </Box>

                    <Box className={classes.tableDetailForm}>
                      {columns
                        .filter(
                          (field) =>
                            field.type !== "metadata" &&
                            !field.nullify &&
                            !field.alwaysHidden
                        )
                        .filter(textFieldFilter)
                        .map((field, i) => {
                          if (field.type === "parent") {
                            return (
                              <div
                                key={field.field + title + i + field.type}
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <FieldWrapper
                                  label={field.headerName}
                                  inactive={
                                    !editDetail ? !newItemCreated : undefined
                                  }
                                  isParent={true}
                                >
                                  {field.children.map((child, i) => (
                                    <div
                                      key={
                                        child.field +
                                        title +
                                        i +
                                        (child.type === undefined
                                          ? "text"
                                          : child.type)
                                      }
                                      style={{
                                        ...getGeneralFormStylesSx(child, true),
                                      }}
                                    >
                                      <FormField
                                        selectedItem={selectedItem}
                                        editedDetails={editedDetails}
                                        setEditedDetails={setEditedDetails}
                                        editDetail={editDetail}
                                        field={child}
                                        parentKey={field.field}
                                        rowKey={rowKey}
                                        newItemCreated={newItemCreated}
                                      />
                                    </div>
                                  ))}
                                </FieldWrapper>
                              </div>
                            );
                          } else if (field.editable === "createOnly") {
                            return (
                              <div
                                key={
                                  field.field +
                                  title +
                                  i +
                                  (newItemCreated
                                    ? field.type ?? "text"
                                    : "metadata")
                                }
                                style={{
                                  ...getGeneralFormStylesSx({
                                    ...field,
                                    editable: newItemCreated ? true : false,
                                  }),
                                  display: "inline-block",
                                }}
                              >
                                <FormField
                                  selectedItem={selectedItem}
                                  editedDetails={editedDetails}
                                  setEditedDetails={setEditedDetails}
                                  editDetail={editDetail}
                                  field={{
                                    ...field,
                                    editable: newItemCreated ? true : false,
                                  }}
                                  rowKey={rowKey}
                                  newItemCreated={newItemCreated}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <div
                                key={field.field + title + i + field.type}
                                style={{
                                  ...getGeneralFormStylesSx(field),
                                  display: "inline-block",
                                }}
                              >
                                <FormField
                                  selectedItem={selectedItem}
                                  editedDetails={editedDetails}
                                  setEditedDetails={setEditedDetails}
                                  editDetail={editDetail}
                                  field={field}
                                  rowKey={rowKey}
                                  newItemCreated={newItemCreated}
                                />
                              </div>
                            );
                          }
                        })}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        )}
        <RHConfirmDialog
          open={dialogProps.open}
          onConfirm={dialogProps.onConfirm}
          onCancel={dialogProps.onCancel}
          title={dialogProps.title}
          content={dialogProps.content}
        />
      </Box>
    </>
  );
}
