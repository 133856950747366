import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  TextField,
  FormControl,
  Autocomplete,
  Tooltip,
  Box,
  Checkbox,
  IconButton,
} from "@mui/material";
import { useDataContext } from "../../contexts/DataContext";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import RHComponentLoader from "./RHComponentLoader";
import { useSandboxStyles } from "../../views/sandbox/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RHFieldWrapper from "./RHFieldWrapper";
import definitions from "./../../common/definitions.json";
import { debounce } from "../../common/helpers";
import { useQuery } from "@tanstack/react-query";
import { useApiToken, apiRequest } from "../../common/apiUtils";
import { InfoIcon } from "../../assets/svgs";

// Adding this because I can't duck type the API response if it has an empty hosts array
const STRING_ARRAY_TYPES = ["armada"];

export default function RHDynamicSelectField(props) {
  const {
    name,
    label,
    required,
    api,
    displayValue,
    value,
    disabled,
    sandboxId,
    onChange,
    sx,
    fullUrl,
    description,
    pageData,
    selectedItem,
    row_key,
    parent_key,
    fullWidth,
  } = props;

  const { dataContext, updateDataContext } = useDataContext();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [options, setOptions] = useState([]);
  const [optionsInitialized, setOptionsInitialized] = useState(false);
  const [isValInitialized, setIsValInitialized] = useState(false);

  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const previousRowKey = useRef(row_key);

  let allOptions = useRef([]);
  let arrayContentType = useRef();
  let isDynamic = false;
  let multiSelect = false;
  let defaultOptions = [];
  let schemaValue;
  let responseDataName;
  let idKey;
  let idKeys;
  let subPage;
  const [isDataReady, setIsDataReady] = useState(false);
  const [textSearchValue, setTextSearchValue] = useState("");
  const [val, setVal] = useState([]);
  const classes = useSandboxStyles();
  // const [data, setData] = useState([]);

  const token = useApiToken();

  console.log("RHDynamicSelectField rendered with props:", props);

  //helper functions

  function alphabetizeNames(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  // Enhanced removeDuplicates function to log potential duplicates
  const removeDuplicates = useCallback((options) => {
    const unique = new Map();
    options.forEach((option) => {
      if (option && unique.has(option.id)) {
        return;
      } else if (option) {
        unique.set(option.id, option);
      }
    });
    return Array.from(unique.values());
  }, []);

  const sanitizeOptions = useCallback((options) => {
    return options.filter((option) => option && option.id && option.title);
  }, []);

  // Function to update options with new data and remove duplicates
  const updateOptions = useCallback(
    (newOptions) => {
      // Sanitize new options
      const sanitizedNewOptions = sanitizeOptions(newOptions);

      // Combine new options with existing ones, ensuring we spread the current options state
      const combinedOptions = [...options, ...sanitizedNewOptions];

      // Remove duplicates
      const uniqueOptions = removeDuplicates(combinedOptions);

      // Check if uniqueOptions is defined and has a length greater or equal to the current options
      if (uniqueOptions && uniqueOptions.length >= options.length) {
        setOptions(uniqueOptions);
      } else {
        // If uniqueOptions is not defined or does not have the correct length, warn about the issue
        console.warn(
          "uniqueOptions is undefined or attempting to reduce options"
        );
      }
    },
    [options, sanitizeOptions, removeDuplicates, value]
  );

  let count = {};
  const mapResponseData = (data) => {
    let title = data?.name ?? data?.[displayValue];

    // If title is undefined or empty, use id as title
    if (title === undefined || title === "") {
      title = data[idKey].toString();
    }

    if (count[title]) {
      count[title]++;
    } else {
      count[title] = 1;
    }

    const uniqueTitle = count[title] > 1 ? `${title}_${count[title]}` : title;

    return {
      title: uniqueTitle, // Now this should be unique or the id
      id: data[idKey],
    };
  };

  /////// Set Up ///////
  for (const field in pageData?.DynamicSelectFields) {
    if (field === name) {
      const tableData = pageData?.DynamicSelectFields[field]?.tableData;
      responseDataName = pageData?.DynamicSelectFields[field]?.responseDataName;
      subPage = pageData?.DynamicSelectFields[field]?.page;
      isDynamic = !!tableData;
      idKey = pageData?.DynamicSelectFields[field]?.idKey;
      idKeys = `${idKey}s`;

      if (parent_key) {
        multiSelect =
          pageData.Columns[parent_key]?.children[field]?.type === "array";
      } else {
        multiSelect = pageData.Columns[field]?.type === "array";
      }

      // Iterate through each item in pageData.Columns
      for (const key in pageData.Columns) {
        if (pageData.Columns.hasOwnProperty(key)) {
          const column = pageData.Columns[key];

          // Check for direct enum_values in the column
          if (
            key === field &&
            Array.isArray(column?.enum_values) &&
            column.enum_values.length > 0
          ) {
            defaultOptions = column.enum_values.map((value, index) => ({
              id: value,
              title: column?.enum_var_names?.[index] ?? "",
            }));
            break; // Break the loop if matching field is found
          }

          // Check for enum_values in children of the column
          if (!parent_key || (parent_key && key === parent_key)) {
            if (column.children && field in column.children) {
              const childColumn = column.children[field];
              if (
                Array.isArray(childColumn?.enum_values) &&
                childColumn.enum_values.length > 0
              ) {
                defaultOptions = childColumn.enum_values.map(
                  (value, index) => ({
                    id: value,
                    title: childColumn?.enum_var_names?.[index] ?? "",
                  })
                );
                break; // Break the loop if matching child field is found
              } else if (
                Array.isArray(childColumn?.default) &&
                childColumn.default.length > 0
              ) {
                defaultOptions = childColumn.default.map((value, index) => ({
                  id: value,
                  title: childColumn?.default?.[index] ?? "",
                }));
                break; // Break the loop if matching child field is found
              }
            }
          }
        }
      }

      schemaValue = definitions?.schemas[tableData];
    }
  }

  /////// FETCHING ///////
  // Determine if the query should be enabled using useMemo
  const isQueryEnabled = useMemo(() => !!sandboxId && !!token && isDynamic);

  // Define replacements object for apiRequest
  const replacements = { sandbox_id: sandboxId };

  const fetchTableData = useCallback(
    async (schemaValue, replacements, textSearchValue, value, options) => {
      console.log("fetchTableData called with:", {
        schemaValue,
        replacements,
        textSearchValue,
        value,
        options,
      });

      if (!schemaValue || !schemaValue.endpoints) {
        console.error("schemaValue or schemaValue.endpoints is undefined");
        return null;
      }

      const endpoint = schemaValue.endpoints.find((e) => e.method === "get");
      if (!endpoint) {
        console.error("Endpoint for GET not found.");
        return null;
      }

      let queryParams = new URLSearchParams();
      let extraOptions = {};

      // Normalize 'value' to an array
      const normalizedValue = Array.isArray(value) ? value : [value];

      const valueExistsInOptions = normalizedValue.some((val) =>
        options.some((option) => option.id === val.id)
      );

      //If we are starting with value(s), we want to go look them up first
      if (!valueExistsInOptions && options.length === 0) {
        normalizedValue.forEach((item) => {
          if (item && item.id) {
            if (Array.isArray(item.id)) {
              item.id.forEach((subItem) => {
                if (subItem && subItem[idKey]) {
                  queryParams.append(idKeys, subItem[idKey]);
                } else if (subItem) {
                  queryParams.append(idKeys, subItem);
                }
              });
            } else {
              queryParams.append(idKeys, item.id);
            }
          }
        });
      } else {
        if (textSearchValue !== "") {
          queryParams.append("name", textSearchValue);
        }
      }
      // Add other fixed query parameters
      queryParams.append("expand", "*");
      queryParams.append("sort_by", idKey);
      queryParams.append("sort_order", "asc");
      queryParams.append("cursor", "0");

      let pageSize;
      if (options.length === 0) {
        pageSize = value.id.length !== 0 ? value.id.length : 50;
      } else {
        pageSize = 50;
      }
      queryParams.append("page_size", pageSize);

      extraOptions.queryParams = queryParams.toString();

      console.log("API request params:", {
        endpoint,
        replacements,
        selectedItem,
        extraOptions,
      });

      try {
        const response = await apiRequest(
          token,
          endpoint,
          replacements,
          selectedItem,
          {},
          extraOptions
        );
        console.log("API response:", response);
        return response;
      } catch (error) {
        console.error("API request failed:", error);
        throw error;
      }
    },
    [token, sandboxId, idKey, idKeys]
  );

  // UseQuery to fetch data
  const {
    data,
    isLoading,
    isError,
    error: queryError,
  } = useQuery({
    queryKey: [
      "dynamicSelectData",
      schemaValue,
      replacements,
      textSearchValue,
      sandboxId,
      value,
    ],
    queryFn: () =>
      fetchTableData(
        schemaValue,
        replacements,
        textSearchValue,
        value,
        options
      ),
    enabled: isQueryEnabled,
    refetchOnWindowFocus: false,
  });

  const onValueChange = (event, newValue) => {
    console.log("onValueChange called with newValue:", newValue);
    const formattedValue =
      arrayContentType.current === "string" && multiSelect
        ? newValue.map((item) => item.id)
        : multiSelect
        ? newValue?.map((item) => ({ [idKey]: item?.id ?? "" })) ?? []
        : newValue?.id ?? "";

    const newEvent = {
      target: {
        name,
        value: formattedValue ?? (newValue !== "" ? newValue : null),
      },
    };

    onChange(newEvent);
    setVal(newValue);
  };

  function formatFetchedOptions(response) {
    console.log("formatFetchedOptions called with:", response);
    if (!response) {
      console.warn("Response is null or undefined in formatFetchedOptions");
      return [];
    }

    const targetData = responseDataName;
    const noDataNode = [
      "rulesets",
      "groups",
      "map_selection_lists",
      "maps",
      "partitions",
      "profiles",
      "profile_lists",
      "pools",
      "roles",
      "ranks",
      "strides",
      "instance_request_templates",
      "partitions",
      "platform_session_templates",
      "deserter_configs",
      "session_templates",
    ];

    if (!targetData) {
      return (
        (response && Array.isArray(response) ? response : [])
          .sort(alphabetizeNames)
          .map((x) => mapResponseData(x)) || []
      );
    }
    if (noDataNode.includes(targetData)) {
      return (
        (response && response[targetData] && Array.isArray(response[targetData])
          ? response[targetData]
          : []
        )
          .sort(alphabetizeNames)
          .map((x) => mapResponseData(x)) || []
      );
    }
    return (
      response && response.data && Array.isArray(response.data)
        ? response.data
        : []
    )
      .sort(alphabetizeNames)
      .map((x) => mapResponseData(x));
  }

  const updateServerOptions = useCallback(
    debounce((event, inputValue, reason) => {
      if (reason === "clear" && !isDynamic) {
        setTextSearchValue("");
      } else {
        setTextSearchValue(inputValue);
      }
    }, 200),
    [] // No dependencies, since you're not using any props or state values here
  );

  const dropdownStyle = {
    fontSize: "0.875rem",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    padding: "8px 16px",
  };

  const renderOption = (props, option, { selected }) => (
    <li {...props} style={dropdownStyle}>
      {multiSelect && (
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
      )}
      {option.title}
    </li>
  );

  const isOptionEqualToValue = (currOption, currValue) => {
    if (
      !currOption ||
      !currValue ||
      (Array.isArray(currValue) && currValue.length === 0 && multiSelect)
    ) {
      return true;
    }

    if (arrayContentType.current === "string") {
      return (
        currOption.id === currValue ||
        (multiSelect && value.includes(currOption.id))
      );
    }

    return currOption.id === currValue.id;
  };

  const explore = () => {
    const currentDrawerState = dataContext?.shouldOpenDrawer ?? false;
    updateDataContext({
      shouldOpenDrawer: !currentDrawerState,
      drawerPage: "Items",
    });
  };

  // Initialize functions
  useEffect(() => {
    console.log(
      "Initial useEffect triggered with value:",
      value,
      "api:",
      api,
      "fullUrl:",
      fullUrl
    );
    if (
      (value && Array.isArray(value) && typeof value[0] === "string") ||
      api?.includes(STRING_ARRAY_TYPES) ||
      fullUrl?.includes(STRING_ARRAY_TYPES)
    ) {
      arrayContentType.current = "string";
    }
  }, [value, api, fullUrl]);

  useEffect(() => {
    console.log("isDynamic useEffect triggered. isDynamic:", isDynamic);
    if (!isDynamic) {
      console.log("Updating options with defaultOptions:", defaultOptions);
      updateOptions(defaultOptions);
      setOptionsInitialized(true);
      setIsDataReady(true);
    }
  }, [isDynamic]);

  useEffect(() => {
    if (data) {
      const fetchedData = data;
      const fetchedOptions = formatFetchedOptions(fetchedData);
      console.log("Formatted options:", fetchedOptions);
      updateOptions(fetchedOptions);
      setOptionsInitialized(true);
      setIsDataReady(true);
    } else {
      console.warn("Fetched data is null or undefined");
    }
  }, [data]);

  useEffect(() => {
    if (isError && queryError) {
      console.error("Error fetching data:", queryError);
      setOptionsInitialized(false);
      if (value) {
        setOptionsInitialized(true);
        setIsDataReady(true);
      }
    }
  }, [isError, queryError]);

  //Initializing Values
  useEffect(() => {
    console.log(
      "Initializing values useEffect triggered. optionsInitialized:",
      optionsInitialized,
      "value:",
      value
    );
    const initializeValue = () => {
      if (!optionsInitialized) return;

      // Preprocess options into a Map for efficient lookup
      const optionsMap = new Map(options.map((option) => [option.id, option]));
      let matchedOptions = [];

      if (multiSelect) {
        // Ensure ids is always an array, regardless of value.id being an array or not
        const ids = Array.isArray(value.id) ? value.id : [value.id];

        matchedOptions = ids.reduce((acc, valIdObj) => {
          // Determine the ID to search for, converting to int if necessary
          let searchId =
            typeof valIdObj === "object" && valIdObj !== null
              ? valIdObj[idKey]
              : valIdObj;
          searchId =
            Number.isInteger(searchId) || !isNaN(searchId)
              ? parseInt(searchId, 10)
              : searchId;
          const foundOption = optionsMap.get(searchId);
          if (foundOption) acc.push(foundOption);
          return acc;
        }, []);
      } else {
        // Directly use the Map for a single select scenario
        const matchingOption = optionsMap.get(value?.id);
        matchedOptions = matchingOption ? [matchingOption] : [];
      }

      console.log("Matched options:", matchedOptions);
      setVal(multiSelect ? matchedOptions : matchedOptions[0] || {});
      setIsValInitialized(true); // Set the initialization flag to true
    };
    // This effect is dependent on row_key. When row_key changes, we reset the initialized flag.
    if (row_key !== previousRowKey.current) {
      console.log("row_key changed. Resetting isValInitialized.");
      setIsValInitialized(false);
    }
    previousRowKey.current = row_key; // Store the current row_key for comparison on the next effect run

    if (!isValInitialized) {
      initializeValue();
    }
  }, [
    options,
    value,
    multiSelect,
    optionsInitialized,
    row_key,
    isValInitialized,
  ]); // Include row_key in the dependency array

  //Hack
  useEffect(() => {
    const originalWarn = console.warn.bind(console);
    console.warn = (...args) => {
      const msg = args[0]; // Get the first argument, which is typically the message
      if (
        typeof msg === "string" &&
        msg.includes("MUI: The value provided to Autocomplete is invalid")
      ) {
        return;
      }
      originalWarn(...args);
    };

    return () => {
      console.warn = originalWarn;
    };
  }, []);

  const getLabelName = (title) => {
    return title.endsWith(" Id") ? title.slice(0, -3) : title;
  };

  console.log(
    "Rendering RHDynamicSelectField. isDataReady:",
    isDataReady,
    "noDataAvailable:",
    noDataAvailable
  );

  if (!isDataReady) {
    if (noDataAvailable) {
      return (
        <div style={{ marginLeft: "-9px", marginBottom: "5px" }}>
          <RHFieldWrapper
            label={getLabelName(props.label)}
            inactive={props.inactive}
            required={required}
          >
            <div style={{ marginTop: "10px" }}>No Data Available</div>
          </RHFieldWrapper>
        </div>
      );
    }
    return <RHComponentLoader size={25} label={label} />;
  }

  return (
    <>
      <FormControl
        sx={{ ...sx, marginBottom: 0 }}
        style={fullWidth ? { maxWidth: "100%" } : {}}
      >
        <Autocomplete
          options={options}
          value={val}
          renderOption={renderOption}
          multiple={multiSelect}
          isOptionEqualToValue={isOptionEqualToValue}
          onInputChange={updateServerOptions}
          onChange={(event, newValue) => {
            console.log(
              "Autocomplete onChange triggered with newValue:",
              newValue
            );
            onValueChange(event, newValue);
          }}
          getOptionLabel={(option) =>
            option?.title === "" ? option?.id.toString() : option?.title ?? ""
          }
          disabled={disabled}
          selectOnFocus
          handleHomeEndKeys
          onOpen={() => {
            // $$Saint
          }}
          required={required}
          disableCloseOnSelect={multiSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              label={
                <Tooltip
                  title={`Search ${getLabelName(label)} - ${description}`}
                  placement="left"
                  disableInteractive
                >
                  <span> {getLabelName(label)} </span>
                </Tooltip>
              }
              InputProps={{
                ...params.InputProps,
                sx: {
                  ...params.InputProps.sx,
                  flexWrap: "wrap", // Allow the tags and adornments to wrap if necessary
                },
                startAdornment: (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {subPage && !disabled && (
                      <IconButton
                        onClick={() => {
                          {
                            explore();
                          }
                        }}
                        size="small"
                        sx={{ marginLeft: "0 !important", opacity: 0.5 }}
                      >
                        <ManageSearchIcon />
                      </IconButton>
                    )}
                    {params.InputProps.startAdornment}
                  </Box>
                ),
              }}
              // Adjust the TextField's style to handle overflow or wrapping as needed
              sx={{
                ...params.sx,
                "& .MuiInputBase-root": {
                  flexWrap: "wrap", // Wrap the input base if necessary
                },
              }}
            />
          )}
        />
      </FormControl>
    </>
  );
}
