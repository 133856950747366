import { useContext, useState, useEffect } from "react";
import { useAuth } from "contexts/AuthContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import AppBar from "components/AppBar";
import Menu from "components/Menu/index.js";
import AccountContext from "contexts/AccountContext";
import LayoutFooter from "components/common/LayoutFooter.js";

export default function Layout({ children }) {
  const { currentStateData, permissionData } = useContext(AccountContext);
  const { logout } = useAuth();

  const [menuOpen, setMenuOpen] = useState(window.innerWidth > 1024);

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--menu-width",
      menuOpen ? "264px" : "0px"
    );
  }, [menuOpen]);

  return permissionData?.permissions.length === 0 &&
    !permissionData?.isGlobalAdmin ? (
    <div>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          No Organizations or Permissions Available
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            No Organizations with permissions are available, please contact your
            administrator.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout}>Logout</Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <div className="block relative">
      <Menu
        parentMenuOpen={setMenuOpen}
        forceMenu={menuOpen}
        toggleMenu={toggleMenu}
      />
      <div
        className={`relative transition-all ${
          menuOpen ? "lg:w-sans-menu lg:left-menu" : "lg:w-full lg:left-0"
        }`}
      >
        <AppBar menuOpen={menuOpen} onToggleMenu={toggleMenu} />
        <div className="bg-zinc-900 text-zinc-100 text-sm flex flex-col min-h-[calc(100vh-50px)]">
          {children}
        </div>
        <div className="w-full fixed bottom-0 left-0 z-[500]">
          <LayoutFooter menuOpen={menuOpen} />
        </div>
      </div>
    </div>
  );
}
