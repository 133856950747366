import React, { useState, useRef, useEffect, useContext } from "react";
import { useAuth } from "../../contexts/AuthContext";
import AccountContext from "../../contexts/AccountContext";
import { readCookie } from "../../common/helpers";
import { motion, AnimatePresence } from "framer-motion";
import { apiRequest } from "../../common/apiUtils";
import {
  CopyIcon,
  LogoutIcon,
  KeyIcon,
  // Add this new import for the icon
  LockIcon,
} from "../../assets/svgs";
import { Link } from "react-router-dom"; // Add this import

function UserMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, logout } = useAuth();
  const { permissionData, orgsData } = useContext(AccountContext);
  const [copiedField, setCopiedField] = useState(null);
  const menuRef = useRef(null);

  const username = user?.name || user?.email?.split("@")[0] || "User";
  const email = user?.email || "No email provided";
  const accountId = permissionData?.account_id || "::ACCOUNT_ID::";

  const parsePermissions = (permissionData) => {
    if (!permissionData || !permissionData.permissions)
      return { accountPerms: [], groupPerms: [] };

    const accountPerms = permissionData.permissions.filter(
      (p) => p.account_permission_id
    );
    const groupPerms = permissionData.permissions.filter(
      (p) => p.group_permission_id
    );

    return { accountPerms, groupPerms };
  };

  const { accountPerms, groupPerms } = parsePermissions(permissionData);

  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [resetMessage, setResetMessage] = useState(null);

  const resetPassword = async () => {
    setIsResettingPassword(true);
    setResetMessage(null);
    try {
      const authToken = readCookie("authSession");
      const response = await passwordResetRequest(
        authToken,
        permissionData?.account_id
      );

      if (response && (response.status === 204 || response.status === 200)) {
        setResetMessage({
          type: "success",
          text: "Password reset email sent. Please check your inbox.",
        });
        setTimeout(() => {
          setIsMenuOpen(false);
          setResetMessage(null);
        }, 3000);
      } else {
        throw new Error(`Unexpected response: ${JSON.stringify(response)}`);
      }
    } catch (error) {
      setResetMessage({
        type: "error",
        text: "Failed to reset password. Please try again later.",
      });
    } finally {
      setIsResettingPassword(false);
    }
  };

  const passwordResetRequest = async (token, accountId) => {
    try {
      const response = await apiRequest(
        token,
        {
          method: "POST",
          path: `/v1/account_pwd_reset/${accountId}`,
        },
        { account_identifier: accountId }
      );

      if (response === null) {
        return { status: 204, message: "Password reset successful" };
      }

      return response;
    } catch (error) {
      throw error;
    }
  };

  // Function to toggle menu open/close
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      setResetMessage(null);
    }
  };

  function copyToClipboard(text, field) {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedField(field);
      setTimeout(() => setCopiedField(null), 1500);
      setIsMenuOpen(false);
    });
  }

  function copyPermissions() {
    const permissions = {
      accountPermissions: accountPerms,
      groupPermissions: groupPerms,
    };
    copyToClipboard(JSON.stringify(permissions), "permissions");
  }

  function copyToken() {
    const token = readCookie("authSession");
    if (token) {
      copyToClipboard(token, "token");
    } else {
      console.error("No auth token found in cookies");
    }
  }

  function handleLogout() {
    setIsMenuOpen(false);
    logout();
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
        setResetMessage(null); // Clear reset message when closing the menu
      }
    }

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={toggleMenu}
        className="flex items-center space-x-2 bg-zinc-800 hover:bg-zinc-700 text-zinc-300 hover:text-white transition-colors rounded-md px-3 py-1 focus:outline-none h-8" // Adjusted padding and added fixed height
      >
        <div className="w-6 h-6 bg-gradient-to-br from-violet-500 to-purple-600 rounded-full flex items-center justify-center">
          {" "}
          <span className="text-white text-xs font-semibold">
            {" "}
            {username.charAt(0).toUpperCase()}
          </span>
        </div>
        <span className="hidden sm:inline text-sm font-medium">{username}</span>
        <motion.svg
          animate={{ rotate: isMenuOpen ? 180 : 0 }}
          className="hidden sm:inline w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </motion.svg>
      </button>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-1 w-80 bg-zinc-800 rounded-lg shadow-xl py-2 z-50 border border-zinc-700 overflow-hidden"
          >
            {/* User Info Section */}
            <div className="px-3 py-2 border-b border-zinc-700 flex items-center space-x-2">
              <div className="w-8 h-8 bg-gradient-to-br from-violet-500 to-purple-600 rounded-full flex items-center justify-center flex-shrink-0">
                <span className="text-white text-sm font-semibold">
                  {username.charAt(0).toUpperCase()}
                </span>
              </div>
              <div className="overflow-hidden">
                <p className="text-sm font-medium text-white truncate">
                  {username}
                </p>
                <p className="text-xs text-zinc-400 truncate">{email}</p>
              </div>
            </div>

            {/* Account ID Section */}
            <div className="px-3 py-2 border-b border-zinc-700">
              <p className="text-xs text-zinc-400 mb-1">Account ID</p>
              <button
                onClick={() => copyToClipboard(accountId, "accountId")}
                className="w-full flex items-center justify-between bg-zinc-900 rounded p-1.5 hover:bg-zinc-700 transition-colors"
              >
                <code className="text-xs text-violet-300 font-mono flex-1 text-left">
                  {accountId}
                </code>
                <CopyIcon className="w-3 h-3 text-violet-400 flex-shrink-0 ml-2" />
              </button>
            </div>

            {/* API Token Section */}
            <div className="px-3 py-2 border-b border-zinc-700">
              <button
                onClick={copyToken}
                className="w-full flex items-center justify-center space-x-2 py-1.5 text-xs font-medium text-white bg-violet-600 rounded-md hover:bg-violet-500 transition-colors duration-200 ease-in-out"
              >
                <CopyIcon className="w-3 h-3" />
                <span>Copy API Token</span>
              </button>
            </div>

            {/* Account Actions Section */}
            <div className="px-3 py-2">
              <div className="flex space-x-2">
                <button
                  onClick={resetPassword}
                  disabled={isResettingPassword}
                  className="flex-1 flex items-center justify-center space-x-1 py-1.5 text-xs font-medium text-violet-400 bg-zinc-700 rounded-md hover:bg-zinc-600 transition-colors duration-200 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <KeyIcon className="w-3 h-3" />
                  <span>
                    {isResettingPassword ? "Resetting..." : "Reset Password"}
                  </span>
                </button>
                <button
                  onClick={handleLogout}
                  className="flex-1 flex items-center justify-center space-x-1 py-1.5 text-xs font-medium text-red-400 bg-zinc-700 rounded-md hover:bg-zinc-600 transition-colors duration-200 ease-in-out"
                >
                  <LogoutIcon className="w-3 h-3" />
                  <span>Log out</span>
                </button>
              </div>
            </div>

            {/* Admin Section (conditionally rendered) */}
            {permissionData?.isGlobalAdmin && (
              <div className="px-3 py-2 border-t border-zinc-700">
                <Link
                  to="/admin"
                  className="w-full flex items-center justify-center space-x-2 py-1.5 text-xs font-medium text-white bg-zinc-700 rounded-md hover:bg-zinc-600 transition-colors duration-200 ease-in-out border border-zinc-600"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <LockIcon className="w-3 h-3" />
                  <span>Admin</span>
                </Link>
              </div>
            )}

            {/* Reset Message (conditionally rendered) */}
            {resetMessage && (
              <div
                className={`mx-3 my-2 p-2 text-xs rounded ${
                  resetMessage.type === "success"
                    ? "bg-green-600 text-white"
                    : "bg-red-600 text-white"
                }`}
              >
                {resetMessage.text}
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {copiedField && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className="absolute right-0 mt-2 bg-green-500 text-white px-4 py-2 rounded shadow-lg z-50 whitespace-nowrap"
        >
          <p className="text-xs font-medium">
            {copiedField === "token" ? "Token" : copiedField} copied to
            clipboard
          </p>
        </motion.div>
      )}
    </div>
  );
}

export default UserMenu;
